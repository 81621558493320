import React from 'react';
import { Button } from '../../components/ui/button';
import { CHAT_REQUEST } from '../../Queries/ChatRequest';
import { useNavigate } from 'react-router-dom';
import { useMutation } from "@apollo/client";

export const TalkButton = ({ location }) => {
    const navigate = useNavigate();
    const agentIds = location.agents.map(agent => agent._id);

    const [createChat, { loading }] = useMutation(CHAT_REQUEST, {
        variables: {
            scenarioId: location.scenarioId,
            locationId: location._id,
            agentIds,
        },
        onCompleted: (data) => {
            if (data?.chatRequest?.conversation?._id) {
                navigate(`/chat/${data.chatRequest.conversation._id}`);
            }
        }
    });

    return (
        <Button
            variant="secondary"
            size="lg"
            className="absolute top-[72px] left-1/2 -translate-x-1/2 z-10"
            onClick={() => createChat()}
            disabled={loading}
        >
            Talk
        </Button>
    );
};