import React from 'react';
import Artifact from './Artifact';

const ArtificeFeed = ({ artifacts }) => {

    return (
        <div className="divide-y divide-gray-200">
            {artifacts.map(artifact => (
                <Artifact art={artifact} />
            ))}
        </div>
    );
}

export default ArtificeFeed