import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_MEMORIES } from './GetMemories';
import { ProfileHeader } from './ProfileHeader';
import { MemoryCard } from './MemoryCard';

const MemoryFeed = () => {
    const { id } = useParams();

    const { loading, error, data, fetchMore } = useQuery(GET_MEMORIES, {
        variables: {
            agentId: id,
            first: 10,
            after: null
        },
        notifyOnNetworkStatusChange: true, // This ensures we get loading state for fetchMore
    });

    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;

        if (
            scrollHeight - scrollTop <= clientHeight * 1.5 &&
            !loading &&
            data?.memories?.pageInfo?.hasNextPage
        ) {
            fetchMore({
                variables: {
                    after: data.memories.pageInfo.endCursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    return {
                        memories: {
                            ...fetchMoreResult.memories,
                            edges: [
                                ...prev.memories.edges,
                                ...fetchMoreResult.memories.edges,
                            ],
                        },
                    };
                },
            });
        }
    };

    if (!id) {
        return (
            <div className="text-center py-4 text-gray-500">
                No agent ID provided
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center py-4 text-red-500">
                Error loading memories: {error.message}
            </div>
        );
    }

    return (
        <div
            className="h-screen overflow-y-auto p-4 bg-gray-50"
            onScroll={handleScroll}
        >
            <div className="max-w-2xl mx-auto">
                <ProfileHeader agentId={id} />

                {data?.memories?.edges.map(memory => (
                    <MemoryCard key={memory._id} memory={memory} />
                ))}

                {loading && (
                    <div className="text-center py-4 text-gray-500">
                        Loading more memories...
                    </div>
                )}

                {!loading && !data?.memories?.pageInfo?.hasNextPage && data?.memories?.edges?.length > 0 && (
                    <div className="text-center py-4 text-gray-500">
                        No more memories to load
                    </div>
                )}
            </div>
        </div>
    );
};

export default MemoryFeed;