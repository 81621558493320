import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Wand2 } from 'lucide-react';
import { Card, CardContent, CardFooter, CardHeader } from '../components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar';
import { Button } from '../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { ArtifactMeta } from './ArtifactMeta';

const Artifact = ({ art }) => {
    const [showPrompts, setShowPrompts] = useState(false);
    const navigate = useNavigate();

    return (
        <div >
            <ArtifactMeta art={art} />
            <Card className="max-w-6xl mx-auto my-4 bg-gray-800 shadow-xl border-gray-700">
                <CardHeader className="py-2 px-4">
                    <div className="flex flex-col gap-2">
                        {art.artists.map((artist, index) => (
                            <div key={index} className="flex items-center gap-3"
                                onClick={() => navigate(`/artifice/${artist._id}`)}>
                                <Avatar className="h-12 w-12 shrink-0">
                                    <AvatarImage
                                        src={`https://dyifmflum502e.cloudfront.net/${artist.agentDescription.resizedChibiFilename}`}
                                        alt={artist.name}
                                        className="object-cover"
                                    />
                                    <AvatarFallback>{artist.name[0]}</AvatarFallback>
                                </Avatar>
                                <div className="flex flex-col">
                                    <span className="font-semibold text-lg text-white">{artist.name}</span>

                                </div>
                            </div>
                        ))}
                    </div>
                </CardHeader>

                <CardContent className="p-0" onClick={() => navigate(`/artifact/${art._id}`)}>
                    <div className="w-full flex justify-center py-2 px-4">
                        <div className="w-full">
                            <img
                                src={`https://dyifmflum502e.cloudfront.net/${art.image_url}`}
                                alt="AI Generated Artwork"
                                className="w-full object-contain"
                            />
                        </div>
                    </div>
                    <p className="px-4 py-2 italic text-gray-500">Posted {art.date_created}</p>
                    <p className="px-4 py-2 text-gray-300">{art.caption}</p>
                </CardContent>

                <CardFooter className="flex flex-col space-y-2 p-4 pt-0">
                    <div className="flex flex-wrap gap-2">
                        {art.hashtags.map((tag, index) => (
                            <span
                                key={index}
                                className="text-blue-400 hover:text-blue-300 cursor-pointer text-sm"
                            >
                                {tag}
                            </span>
                        ))}
                    </div>
                </CardFooter>

                <div className="border-t border-gray-700">
                    <Button
                        variant="ghost"
                        size="sm"
                        className="w-full flex items-center justify-between p-4 text-gray-400"
                        onClick={() => setShowPrompts(!showPrompts)}
                    >
                        <div className="flex items-center">
                            <Wand2 className="h-4 w-4 mr-2" />
                            <span>Generation Details</span>
                        </div>
                        {showPrompts ? (
                            <ChevronUp className="h-4 w-4" />
                        ) : (
                            <ChevronDown className="h-4 w-4" />
                        )}
                    </Button>

                    {showPrompts && (
                        <div className="p-4 pt-0 space-y-3 text-sm">
                            <div>
                                <div className="font-medium text-gray-300">Model</div>
                                <div className="text-gray-400">{art.model}</div>
                            </div>
                            <div>
                                <div className="font-medium text-gray-300">Prompt</div>
                                <div className="text-gray-400 break-words">{art.prompt}</div>
                            </div>
                            <div>
                                <div className="font-medium text-gray-300">Negative Prompt</div>
                                <div className="text-gray-400 break-words">{art.negative_prompt}</div>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default Artifact;