import React from 'react';
import ArtificeHeader from './ArtificeHeader';
import ArtificeFeed from './ArtificeFeed';

const ArtificeScreen = ({ agent, scenario, feed }) => {
    return (
        <div className="w-full bg-gray-900">
            <div className="max-w-6xl mx-auto min-h-screen">
                <ArtificeHeader agent={agent}
                    scenario={scenario} />
                <ArtificeFeed artifacts={feed} />
            </div>
        </div>
    );
};

export default ArtificeScreen;