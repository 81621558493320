import { useQuery } from '@apollo/client'
import React from 'react';
import ReactLoading from 'react-loading';
import { useParams } from 'react-router-dom';
import ArtificeScreen from './ArtificeScreen';
import { GET_SCENARIO_ARTIFICE } from '../Queries/GetScenarioArtifice';

const ScenarioArtificeScreen = (props) => {

    const { id } = useParams();

    const { loading, error, data } = useQuery(GET_SCENARIO_ARTIFICE, {
        variables: { scenarioId: props.scenarioId != null ? props.scenarioId : id }
    });

    if (loading) {
        return (
            <ReactLoading type="spinningBubbles" color="#444" />
        );
    }
    else if (error) {
        return (
            <p>Something went wrong...</p>
        );
    }
    else {
        return (
            <ArtificeScreen
                scenario={data.scenario.scenario}
                feed={data.scenario.scenario.artifice} />
        )
    }
}

export default ScenarioArtificeScreen;