import React from 'react';
import { Button } from '../../components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from '../../components/ui/dialog';
import SocialLinksCard from '../../components/SocialLinksCard';

const CharacterLabel = ({ agent, height, buttonSize }) => {
    const y = 0 + (height * 0.9);

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button
                    style={{
                        position: 'absolute',
                        zIndex: 5,
                        marginTop: 16,
                        left: '50%',
                        top: y,
                        transform: 'translate(-50%)'
                    }}
                    variant="secondary"
                    className={`text-white ${buttonSize}`}
                >
                    {agent.name}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-md border-none p-0">
                <SocialLinksCard agent={agent} />
            </DialogContent>
        </Dialog>
    );
};

export default CharacterLabel;