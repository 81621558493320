import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const ChirpyHeader = ({ agent }) => {

    const navigate = useNavigate();

    return (
        <div className="w-full">
            {/* Header with back button and basic info */}
            <div className="sticky top-0 z-10 bg-white/80 backdrop-blur-md border-b border-gray-200">
                <div className="flex items-center p-4">
                    <button className="p-2 rounded-full hover:bg-gray-200"
                        onClick={() => navigate(-1)}>
                        <ArrowLeft size={20} />
                    </button>
                    <div className="ml-6">
                        <h2 className="font-bold text-xl">{agent.name}</h2>
                        <span className="text-gray-500 text-sm">
                            {agent.twitterProfile.stats.tweet_count} chirps
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChirpyHeader