import React, { useState } from 'react';
import ChirpyProfile from './ChirpyProfile';
import Feed from './Feed';

const ChirpyScreen = ({ agent, scenario, feed }) => {
    const [activeTab, setActiveTab] = useState('chirps');

    // Filter posts based on active tab
    const getFilteredPosts = () => {
        if (!feed) return [];

        switch (activeTab) {
            case 'chirps':
                return feed.filter(post => !post.reply_to);
            case 'replies':
                return feed.filter(post => post.reply_to);
            case 'media':
                return feed.filter(post => post.imageUrl);
            case 'likes':
                return feed.filter(post => post.liked);
            default:
                return feed;
        }
    };

    const tabs = [
        { id: 'chirps', label: 'Chirps' },
        { id: 'replies', label: 'Replies' },
        { id: 'media', label: 'Media' },
        { id: 'likes', label: 'Likes' }
    ];

    return (
        <div className="max-w-2xl mx-auto bg-white min-h-screen border-x border-gray-200">
            <ChirpyProfile agent={agent} scenario={scenario} />

            {/* Navigation */}
            <div className="border-b border-gray-200">
                <nav className="flex">
                    {tabs.map(tab => (
                        <button
                            key={tab.id}
                            onClick={() => setActiveTab(tab.id)}
                            className={`flex-1 px-4 py-4 hover:bg-gray-50 font-medium transition-colors
                                ${activeTab === tab.id
                                    ? 'border-b-2 border-blue-500 text-blue-500'
                                    : 'text-gray-500'}`}
                        >
                            {tab.label}
                        </button>
                    ))}
                </nav>
            </div>

            {/* Feed with filtered posts */}
            <Feed
                posts={getFilteredPosts()}
                showReplies={activeTab === 'replies'}
            />

            {/* Empty state messages */}
            {getFilteredPosts().length === 0 && (
                <div className="p-8 text-center text-gray-500">
                    {activeTab === 'chirps' && "No chirps yet"}
                    {activeTab === 'replies' && "No replies yet"}
                    {activeTab === 'media' && "No media posts yet"}
                    {activeTab === 'likes' && "TODO: No liked posts yet"}
                </div>
            )}
        </div>
    );
};

export default ChirpyScreen;