import { gql } from '@apollo/client'

export const GET_AGENT_PROFILE = gql`
    query AGENT($agentId: ID!)
    {
        agent(id: $agentId)
        {
            success
            agent 
            {
                _id
                name
                age
                gender
                status
                emoji
                description
                agentDescription
                {
                    eyes
                    hair
                    clothing
                    distinguishingFeatures
                    portraitFilename
                    iconFilename
                    resizedIconFilename
                    chibiFilename
                    resizedChibiFilename
                }
                agentLocation
                {
                    currentScenarioId
                    scenario
                    {
                        name
                        imageFilename
                    }
                }
            }
        },
    }`