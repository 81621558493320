import { gql } from '@apollo/client'

export const GET_SCENARIO_CHIRPS = gql`
    query Scenario($scenarioId: ID!) {
        scenario(id: $scenarioId) {
            success
            scenario {
                _id
                imageFilename
                name
                description
                currentDateTime
                
                feed
                {
                    _id
                    time
                    content
                    imageUrl
                    author
                    {
                        username
                        avatar
                    }
                    stats
                    {
                        like_count
                        reply_count
                    }
                    reply_to
                    {
                        _id
                        author
                        {
                            avatar
                            username
                        }
                        content
                    }
                }
            }
        }
    }
`;