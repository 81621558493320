import { gql } from '@apollo/client';

export const GET_MEMORIES = gql`
  query GetMemories($agentId: ID!, $first: Int!, $after: String) {
    memories(agentId: $agentId, first: $first, after: $after) {
      edges {
        _id
        description
        time
        importance
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;