// SocialLinksCard.js
import React from 'react';
import { Feather, Bird, Brush, Brain, Calendar } from 'lucide-react';

const SocialLinksCard = ({ agent }) => {
    const imageUrl = agent.agentDescription?.portraitFilename
        ? `https://dyifmflum502e.cloudfront.net/${agent.agentDescription.portraitFilename}`
        : '/api/placeholder/128/224';

    const getSocialLinks = (characterId) => [
        {
            name: 'Quill',
            username: `${agent?.name} personal blog`,
            icon: Feather,
            color: 'text-cyan-500',
            hoverColor: 'hover:bg-cyan-50',
            url: `/quill/${characterId}`
        },
        {
            name: 'Chirpy',
            username: `Social media for ${agent?.name}`,
            icon: Bird,
            color: 'text-sky-500',
            hoverColor: 'hover:bg-sky-50',
            url: `/chirpy/${characterId}`
        },
        {
            name: 'Artifice',
            username: `Artist profile of ${agent?.name}`,
            icon: Brush,
            color: 'text-violet-500',
            hoverColor: 'hover:bg-violet-50',
            url: `/artifice/${characterId}`
        },
        {
            name: 'Stream',
            username: `Stream of consciousness for ${agent?.name}`,
            icon: Brain,
            color: 'text-emerald-500',
            hoverColor: 'hover:bg-emerald-50',
            url: `/stream/${characterId}`
        },
        {
            name: 'Plannerly',
            username: `Daily schedule for ${agent?.name}`,
            icon: Calendar,
            color: 'text-amber-500', // A warm orange shade
            hoverColor: 'hover:bg-amber-50',
            url: `/plannerly/${characterId}`
        },
    ];

    const socialLinks = getSocialLinks(agent?._id);

    return (
        <div className="w-full bg-white">
            <div className="p-6">
                <div className="text-center mb-6">
                    <div className="w-32 h-32 mx-auto rounded-full border-4 overflow-hidden relative">
                        <div className="absolute inset-0 bg-gradient-to-br from-indigo-700 via-slate-900 to-purple-900" />
                        <img
                            src={imageUrl}
                            alt={agent.name}
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-contain"
                            style={{ objectPosition: 'center 30%' }}
                        />
                    </div>
                    <h2 className="text-xl font-bold text-gray-800 mt-4">{agent?.name}</h2>
                    <p className="text-gray-600">{agent?.description || 'Digital Character'}</p>
                </div>

                <div className="space-y-3">
                    {socialLinks.map((link) => {
                        const Icon = link.icon;
                        return (
                            <a
                                key={link.name}
                                href={link.url}
                                className={`block p-3 rounded-lg border border-gray-200 
                                    transition-colors duration-200 ${link.hoverColor}
                                    hover:border-gray-300`}
                            >
                                <div className="flex items-center space-x-3">
                                    <Icon className={`w-5 h-5 ${link.color}`} />
                                    <div>
                                        <div className="font-medium text-gray-800">{link.name}</div>
                                        <div className="text-sm text-gray-500">{link.username}</div>
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SocialLinksCard;