import { gql } from '@apollo/client'

export const GET_SCENARIO = gql`
    query Scenario($scenarioId: ID!) {
        scenario(id: $scenarioId) {
            success
            scenario {
                _id
                imageFilename
                name
                description
                currentDateTime
                
                newspapers {
                    _id
                    name
                    description
                }
                quests
                {
                    title
                    description
                    difficulty
                    timeframe
                    suggested_approach
                    reward_text
                    completed
                }
            }
        }
    }
`;