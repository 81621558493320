import { useQuery } from '@apollo/client'
import React from 'react';
import ReactLoading from 'react-loading';
import ChirpScreen from './ChirpScreen';
import { GET_CHIRP } from '../Queries/GetChirp';
import { useParams } from 'react-router-dom';

const AgentChirpScreen = (props) => {

    const { id } = useParams();

    const { loading, error, data } = useQuery(GET_CHIRP, {
        variables: { chirpId: props.chirpId != null ? props.chirpId : id }
    });

    if (loading) {
        return (
            <ReactLoading type="spinningBubbles" color="#444" />
        );
    }
    else if (error) {
        return (
            <p>Something went wrong...</p>
        );
    }
    else {
        return (
            <div>
                <ChirpScreen chirp={data.tweet.tweet} />
            </div>
        )
    }
}

export default AgentChirpScreen;