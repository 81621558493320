import React, { useState } from 'react';
import { UserCircle, Book, Menu, X } from 'lucide-react';
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from '../Profile/LogoutButton';
import LoginButton from '../Profile/LoginButton';
import Profile from '../Profile/Profile';
import CreateScenarioCard from './CreateScenarioCard';
import UserScenarios from '../Queries/GetUserScenarios';

const UserProfileLayout = () => {
    const [activeSection, setActiveSection] = useState('scenarios');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { user, isAuthenticated } = useAuth0();

    const NavButton = ({ active, onClick, icon: Icon, children }) => (
        <button
            onClick={onClick}
            className={`w-full flex items-center space-x-3 px-4 py-3 rounded-md transition-colors
                ${active ? 'bg-blue-50 text-blue-700' : 'text-gray-700 hover:bg-gray-50'}`}
        >
            <Icon className="w-5 h-5" />
            <span className="font-medium">{children}</span>
        </button>
    );

    const ProfileSection = () => (
        <div className="p-4 md:p-6 max-w-2xl mx-auto">
            {!isAuthenticated ? (
                <div className="text-center">
                    <h2 className="text-xl font-semibold mb-4">Sign in to access your profile</h2>
                    <LoginButton />
                </div>
            ) : (
                <div className="space-y-6">
                    <h2 className="text-2xl font-bold">Profile</h2>
                    <div className="bg-white rounded-lg shadow p-6 space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Username</label>
                            <div className="mt-2 text-lg">{user.name}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    const ScenariosSection = () => (
        <div className="p-4 md:p-6 max-w-4xl mx-auto space-y-6">
            <h2 className="text-2xl font-bold">Scenarios</h2>
            {!isAuthenticated ? (
                <div className="text-center bg-white rounded-lg shadow p-6">
                    <h3 className="text-xl font-semibold mb-4">Sign in to create and view scenarios</h3>
                    <LoginButton />
                </div>
            ) : (
                <div className="space-y-8">
                    <div className="bg-white rounded-lg shadow">
                        <CreateScenarioCard />
                    </div>
                    <div className="bg-white rounded-lg shadow p-6">
                        <h3 className="text-lg font-semibold mb-4">Your Scenarios</h3>
                        <UserScenarios userId={user.sub} />
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <div className="flex min-h-screen bg-gray-50">
            {/* Sidebar */}
            <aside
                className={`
                    fixed md:relative z-40 h-screen
                    w-64 bg-white border-r border-gray-200
                    transition-transform duration-300
                    ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
                `}
            >
                <div className="flex flex-col h-full p-4 md:p-6">
                    {isAuthenticated && (
                        <div className="flex items-center space-x-3 mb-8">
                            <Profile className="w-10 h-10 text-gray-400" />
                        </div>
                    )}

                    <nav className="space-y-2">
                        <NavButton
                            active={activeSection === 'scenarios'}
                            onClick={() => {
                                setActiveSection('scenarios');
                                setIsSidebarOpen(false);
                            }}
                            icon={Book}
                        >
                            Scenarios
                        </NavButton>

                        <NavButton
                            active={activeSection === 'profile'}
                            onClick={() => {
                                setActiveSection('profile');
                                setIsSidebarOpen(false);
                            }}
                            icon={UserCircle}
                        >
                            Profile
                        </NavButton>

                        <LogoutButton
                            className="w-full flex items-center space-x-3 px-4 py-3 rounded-md 
                                     text-gray-700 hover:bg-gray-50 transition-colors"
                        />
                    </nav>
                </div>
            </aside>

            {/* Mobile overlay */}
            {isSidebarOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
                    onClick={() => setIsSidebarOpen(false)}
                />
            )}

            {/* Main content area */}
            <div className="flex-1 flex flex-col">
                {/* Mobile header */}
                <header className="md:hidden bg-white border-b px-4 py-3 flex items-center">
                    <button
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <Menu className="w-6 h-6" />
                    </button>
                    <h1 className="ml-4 text-lg font-semibold">Dashboard</h1>
                </header>

                {/* Main content */}
                <main className="flex-1">
                    {activeSection === 'profile' ? <ProfileSection /> : <ScenariosSection />}
                </main>
            </div>
        </div>
    );
};

export default UserProfileLayout;