import React, { useState } from 'react';
import { Button } from '../../components/ui/button';
import { Status } from './Status';
import { CharacterProfile } from '../../components/CharacterProfile';

export const InteriorCharacter = ({ agent, position, scale }) => {
    const [showProfile, setShowProfile] = useState(false);
    const width = 512 * scale.width;
    const height = 896 * scale.height;

    return (
        <div
            className="absolute"
            style={{
                left: position.x,
                top: position.y - 16,
                width,
                height
            }}
        >
            <Status agent={agent} />

            <img
                src={`https://dyifmflum502e.cloudfront.net/${agent.agentDescription.iconFilename}`}
                width={width}
                height={height}
                className="absolute top-4 z-10"
                alt={`Character ${agent._id}`}
            />

            <Button
                variant="secondary"
                size="lg"
                className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20"
                onClick={() => setShowProfile(true)}
            >
                {agent.name}
            </Button>

            <CharacterProfile
                agent={agent}
                isOpen={showProfile}
                onClose={() => setShowProfile(false)}
            />
        </div>
    );
};