import { useMemo } from 'react';

export const useLocationNavigation = (location) => {
    return useMemo(() => {
        let prev = null;
        let next = null;

        if (location.parentLocation?.locations) {
            const siblings = location.parentLocation.locations;
            const currentIndex = siblings.findIndex(loc => loc._id === location._id);

            if (currentIndex === 0) {
                prev = location.parentLocation;
            } else if (currentIndex > 0) {
                prev = siblings[currentIndex - 1];
            }

            if (currentIndex < siblings.length - 1) {
                next = siblings[currentIndex + 1];
            }
        } else if (location.locations?.length > 0) {
            next = location.locations[0];
        }

        return { prev, next };
    }, [location]);
};