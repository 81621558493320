import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../components/ui/button";
import React from "react";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = () => {
        const redirectUri = window.location.pathname;
        loginWithRedirect({
            appState: { returnTo: redirectUri }
        });
    };

    return <Button className="btn btn-custom btn-lg page-scroll"
        onClick={handleLogin}>Log In</Button>;
};

export default LoginButton;