import { Button } from '../../components/ui/button';

export const ChatCard = ({ width, height, handlePlayerText }) => {
    const border = 100;
    const left = border;
    const top = height * 0.75;
    const cardWidth = width - (left * 2);
    const cardHeight = height - top - (border / 2);

    return (
        <div
            className="absolute rounded-lg border-2 border-white bg-black/75 p-5 z-10"
            style={{
                left: `${left}px`,
                top: `${top}px`,
                width: `${cardWidth}px`,
                height: `${cardHeight}px`
            }}
        >
            <form onSubmit={handlePlayerText}>
                <label htmlFor="playerTextArea" className="block text-white">
                    Player:
                </label>
                <textarea
                    id="playerTextArea"
                    className="w-full bg-gray-800 text-white rounded-md p-2 border border-gray-700"
                />
                <Button
                    type="submit"
                    variant="secondary"
                    size="sm"
                    className="absolute right-4 bottom-2 z-20"
                >
                    Continue
                </Button>
            </form>
        </div>
    );
};