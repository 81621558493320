import React, { useState } from 'react';
import { Button } from '../../components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import { useNavigate } from 'react-router-dom';
import { useLocationNavigation } from './useLocationNavigation';
import { TalkButton } from './TalkButton';

export const InteriorLayout = ({ location, children }) => {
    const navigate = useNavigate();
    const { prev, next } = useLocationNavigation(location);
    const [showDetails, setShowDetails] = useState(false);

    return (
        <div className="relative w-full h-screen">
            <div className="absolute top-4 left-4 z-10">
                <Button
                    variant="secondary"
                    size="lg"
                    onClick={() => navigate(`/scenario/${location.scenarioId}`)}
                >
                    Exit
                </Button>
            </div>

            <div className="absolute top-4 left-1/2 -translate-x-1/2 z-10 flex flex-col gap-2">
                <Button
                    variant="secondary"
                    size="lg"
                    onClick={() => setShowDetails(true)}
                >
                    {location.name}
                </Button>

                {location.agents.length > 0 && (
                    <TalkButton location={location} />
                )}
            </div>

            <div className="absolute bottom-4 w-full px-4 flex justify-between z-10">
                {prev && (
                    <Button
                        variant="secondary"
                        onClick={() => navigate(`/location/${prev._id}`)}
                    >
                        ⬅ Previous
                    </Button>
                )}
                {next && (
                    <Button
                        variant="secondary"
                        className={prev ? '' : 'ml-auto'}
                        onClick={() => navigate(`/location/${next._id}`)}
                    >
                        Next ➡
                    </Button>
                )}
            </div>

            <Dialog open={showDetails} onOpenChange={setShowDetails}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>{location.name}</DialogTitle>
                    </DialogHeader>
                    <p>{location.description}</p>
                </DialogContent>
            </Dialog>

            {children}
        </div>
    );
};