import React from 'react';
import { useNavigate } from 'react-router-dom';
import { User, MapPin } from 'lucide-react';
import ChirpyHeader from './ChirpyHeader';

const ChirpyProfile = ({ agent, scenario }) => {
    const imageUrl = agent?.agentDescription?.portraitFilename
        ? `https://dyifmflum502e.cloudfront.net/${agent?.agentDescription?.portraitFilename}`
        : '/api/placeholder/128/224';

    const background = scenario.imageFilename;

    const navigate = useNavigate();

    return (
        <div className="w-full">
            {agent ? (
                <ChirpyHeader agent={agent} />
            ) : (
                <div />
            )}

            {/* Cover image */}
            <div className="h-48 relative overflow-hidden">
                {background ? (
                    <img
                        src={`https://dyifmflum502e.cloudfront.net/${background}`}
                        alt="Profile background"
                        className="w-full h-full object-cover"
                    />
                ) : (
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500" />
                )}
            </div>

            {agent ? (
                < div className="p-4">
                    <div className="flex justify-between">
                        <div className="relative -mt-20">
                            {imageUrl ? (
                                <div className="w-32 h-32 rounded-full border-4 border-white overflow-hidden relative">
                                    <div className="absolute inset-0 bg-gradient-to-br from-indigo-700 via-slate-900 to-purple-900" />
                                    <img
                                        src={imageUrl}
                                        alt={agent.name}
                                        className="absolute inset-0 w-full h-full object-contain"
                                    />
                                </div>
                            ) : (
                                <div className="w-32 h-32 rounded-full border-4 border-white bg-gray-200 flex items-center justify-center">
                                    <User size={48} className="text-gray-400" />
                                </div>
                            )}
                        </div>
                        <button className="px-4 py-2 rounded-full border border-gray-300 font-bold hover:bg-gray-50">
                            Edit profile
                        </button>
                    </div>

                    {/* Profile info */}
                    <div className="mt-4">
                        <h2 className="text-xl font-bold"
                            onClick={() => navigate(`/chirpy/${agent._id}`)}>
                            {agent.name}
                        </h2>
                        <div className="flex items-center text-gray-500 mt-1"
                            onClick={() => navigate(`/scenario/${agent.agentLocation.scenario._id}`)}>
                            <MapPin size={16} className="mr-1" />
                            <span className="text-sm">{agent.agentLocation.scenario.name}</span>
                        </div>
                        <p className="mt-3 text-gray-800">{agent.description}</p>

                        {/* Current mood */}
                        <div className="mt-3 text-gray-600">
                            <span>{agent.emoji} {agent.status}</span>
                        </div>

                        {/* Stats */}
                        <div className="flex space-x-6 mt-4">
                            <div className="flex items-center space-x-1">
                                <span className="font-bold">{agent.twitterProfile.stats.following_count}</span>
                                <span className="text-gray-500">Following</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="font-bold">{agent.twitterProfile.stats.followers_count}</span>
                                <span className="text-gray-500">Followers</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div />
            )}

        </div>
    );
};

export default ChirpyProfile