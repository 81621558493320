import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "../../components/ui/card";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Separator } from "../../components/ui/separator";
import { useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const EditionArea = ({ edition }) => {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    // If no edition data, show empty state
    if (!edition?.newspaper) {
        return (
            <div className="w-full max-w-7xl mx-auto p-4 text-center">
                <h2 className="text-xl font-bold mb-2">No Editions Available</h2>
                <p className="text-gray-600">There are no editions published yet.</p>
            </div>
        );
    }

    return (
        <div className="w-full max-w-7xl mx-auto p-2 sm:p-4 bg-white min-h-screen text-black">
            {/* Back Navigation */}
            <div className="text-left mb-4 flex items-center justify-between">
                <span
                    className="text-sm text-gray-600 hover:text-gray-800 cursor-pointer"
                    onClick={() => navigate(`/scenario/${edition.newspaper.scenario._id}`)}
                >
                    ← Return to {edition.newspaper.scenario.name}
                </span>
                <button
                    className="md:hidden p-2 hover:bg-gray-100 rounded-lg"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                    {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
            </div>

            {/* Newspaper Header */}
            <div className="text-center mb-6 border-b-2 border-black pb-4">
                <h1 className="text-2xl sm:text-4xl font-bold font-serif mb-2">
                    {edition.newspaper.name}
                </h1>
                <div className="text-lg sm:text-xl mb-2">
                    {new Date(edition.date).toLocaleDateString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })}
                </div>
                <p className="text-sm sm:text-base text-gray-600 italic">
                    {edition.newspaper.description}
                </p>
            </div>

            <div className="flex flex-col md:flex-row gap-6">
                {/* Mobile Sidebar Overlay */}
                {isSidebarOpen && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
                        onClick={() => setIsSidebarOpen(false)}
                    />
                )}

                {/* Sidebar with edition links */}
                <div className={`
                    fixed md:relative top-0 right-0 h-full md:h-auto
                    w-64 flex-shrink-0 bg-white z-50 
                    transform transition-transform duration-300 ease-in-out
                    ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full md:translate-x-0'}
                `}>
                    <Card>
                        <CardHeader>
                            <CardTitle className="text-lg">Editions</CardTitle>
                        </CardHeader>
                        <CardContent className="p-0">
                            <ScrollArea className="h-[calc(100vh-120px)] md:h-[600px]">
                                {edition.newspaper.editions?.map((edition) => (
                                    <button
                                        key={edition.date}
                                        onClick={() => {
                                            navigate(`/edition/${edition._id}`);
                                            setIsSidebarOpen(false);
                                        }}
                                        className={`block w-full text-left px-4 py-2 hover:bg-gray-100 
                                            ${edition.date === edition.date ? 'bg-gray-100 font-semibold' : ''}`}
                                    >
                                        {new Date(edition.date).toLocaleDateString('en-US', {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })}
                                    </button>
                                ))}
                            </ScrollArea>
                        </CardContent>
                    </Card>
                </div>

                {/* Main content area */}
                <div className="flex-1">
                    <ScrollArea className="h-[calc(100vh-200px)] md:h-[800px]">
                        <div className="md:columns-2 gap-6 [&>*:first-child]:mt-0">
                            {edition.articles?.map((article, index) => (
                                <article
                                    key={index}
                                    className={`break-inside-avoid-column mb-6 
                                        ${index === 0 ? 'md:column-span-all mb-6' : ''}`}
                                >
                                    <h2 className="text-xl md:text-2xl font-bold font-serif mb-4">
                                        {article.headline}
                                    </h2>
                                    <Separator className="mb-4" />
                                    {article.body.split('\n').map((paragraph, pIndex) => (
                                        <p key={pIndex} className="mb-4 text-justify leading-relaxed text-sm md:text-base">
                                            {paragraph}
                                        </p>
                                    ))}
                                </article>
                            ))}
                        </div>
                    </ScrollArea>
                </div>
            </div>
        </div>
    );
};

export default EditionArea;