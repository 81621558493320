import React from 'react';
import ChirpyProfile from './ChirpyProfile';
import Chirp from './Chirp';

const ChirpScreen = ({ chirp }) => {

    return (
        <div className="max-w-2xl mx-auto bg-white min-h-screen border-x border-gray-200">
            <ChirpyProfile agent={chirp.agent}
                scenario={chirp.agent.agentLocation.scenario} />

            {/* Feed */}
            <Chirp post={chirp} showReplies={true} showOriginal={true} />
        </div>
    );
};

export default ChirpScreen;