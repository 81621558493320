import ReactLoading from 'react-loading';

export const ThinkingCard = ({ width, height }) => {
    const border = 100;
    const left = border;
    const top = height * 0.75;
    const cardWidth = width - (left * 2);
    const cardHeight = height - top - (border / 2);

    return (
        <div
            className="absolute rounded-lg border-2 border-white bg-black/75 p-5 z-10 flex items-center justify-center"
            style={{
                left: `${left}px`,
                top: `${top}px`,
                width: `${cardWidth}px`,
                height: `${cardHeight}px`
            }}
        >
            <ReactLoading type="spinningBubbles" color="#444" />
        </div>
    );
};