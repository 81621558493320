export const ConversationBackground = ({ conversation, width, height }) => {
    let backgroundImg = "";
    if (conversation.location) {
        backgroundImg = conversation.location.resizedImageInteriorFilename;
    } else if (conversation.scenario) {
        backgroundImg = conversation.scenario.imageFilename;
    } else if (conversation.initiatingAgent) {
        backgroundImg = conversation.initiatingAgent.agentLocation.scenario.imageFilename;
        if (conversation.initiatingAgent.agentLocation.location) {
            backgroundImg = conversation.initiatingAgent.agentLocation.location.resizedImageInteriorFilename;
        }
    }

    return (
        <img
            src={`https://dyifmflum502e.cloudfront.net/${backgroundImg}`}
            className="absolute -z-10 grayscale-20 brightness-50 blur-sm"
            width={width}
            height={height}
            alt="Background"
        />
    );
};