import { useQuery } from '@apollo/client'
import React from 'react';
import ReactLoading from 'react-loading';
import ChirpyScreen from './ChirpyScreen';
import { GET_AGENT_CHIRPS } from '../Queries/GetAgentChirps';
import { useParams } from 'react-router-dom';

const AgentChirpyScreen = (props) => {

    const { id } = useParams();

    const { loading, error, data } = useQuery(GET_AGENT_CHIRPS, {
        variables: { agentId: props.agentId != null ? props.agentId : id }
    });

    if (loading) {
        return (
            <ReactLoading type="spinningBubbles" color="#444" />
        );
    }
    else if (error) {
        return (
            <p>Something went wrong...</p>
        );
    }
    else {
        return (
            <div>
                <ChirpyScreen agent={data.agent.agent}
                    scenario={data.agent.agent.agentLocation.scenario}
                    feed={data.agent.agent.feed} />
            </div>
        )
    }
}

export default AgentChirpyScreen;