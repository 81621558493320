import React, { useState } from 'react';
import GameWindowSize from '../../GameWindowSize';
import BackButton from './BackButton';
import ConversationCharacters from './ConversationCharacters';
import { ConversationCard } from './ConversationCard';
import { ConversationBackground } from './ConversationBackground';

const ConversationScreen = (props) => {
    const [isSummary, setIsSummary] = useState(true);
    const [dialogueIndex, setDialogueIndex] = useState(0);

    const increment = () => setDialogueIndex(prevIndex => prevIndex + 1);

    const handleNext = (event) => {
        if (isSummary) {
            setIsSummary(false);
        } else {
            increment();
        }
    };

    const gameWindowSize = GameWindowSize({ width: 2048, height: 1024 });
    const currentY = gameWindowSize.height * 0.02;

    return (
        <div className="relative">
            <ConversationBackground
                conversation={props.conversation}
                width={gameWindowSize.width}
                height={gameWindowSize.height}
            />

            {/* Back Button positioned in top-left corner */}
            <div className="absolute top-4 left-4">
                <BackButton />
            </div>

            <ConversationCharacters
                isSummary={isSummary}
                dialogueIndex={dialogueIndex}
                dialogue={props.conversation.dialogue}
                agents={props.conversation.agents}
                width={gameWindowSize.width}
                height={gameWindowSize.height}
                widthMultiplier={gameWindowSize.widthMultiplier}
                heightMultiplier={gameWindowSize.heightMultiplier}
                y={currentY}
            />

            <ConversationCard
                isSummary={isSummary}
                showNextButton={isSummary ||
                    dialogueIndex < (props.conversation.dialogue.length - 1)}
                dialogueIndex={dialogueIndex}
                dialogue={props.conversation.dialogue}
                summary={props.conversation.summary}
                width={gameWindowSize.width}
                height={gameWindowSize.height}
                handleNext={handleNext}
            />
        </div>
    );
};

export default ConversationScreen;