import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
    const navigate = useNavigate();

    return (
        <Button
            variant="secondary"
            size="lg"
            className="relative z-10"
            onClick={() => navigate(-1)}
        >
            <span className="text-xl font-bold">Back</span>
        </Button>
    );
};

export default BackButton;