import { gql } from '@apollo/client'

export const GET_AGENT_CHIRPS = gql`
    query AGENT($agentId: ID!)
    {
        agent(id: $agentId)
        {
            success
            agent 
            {
                _id
                name
                age
                gender
                status
                emoji
                description
                agentDescription
                {
                    eyes
                    hair
                    clothing
                    distinguishingFeatures
                    portraitFilename
                    iconFilename
                    resizedIconFilename
                    chibiFilename
                    resizedChibiFilename
                }
                twitterProfile
                {
                    stats
                    {
                        tweet_count
                        followers_count
                        following_count
                    }
                }
                feed
                {
                    _id
                    time
                    content
                    imageUrl
                    author
                    {
                        username
                        avatar
                    }
                    stats
                    {
                        like_count
                        reply_count
                    }
                    reply_to
                    {
                        _id
                        author
                        {
                            avatar
                            username
                        }
                        content
                    }
                }
                agentLocation
                {
                    currentScenarioId
                    scenario
                    {
                        _id
                        name
                        imageFilename
                    }
                }
            }
        }
    }`