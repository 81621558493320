import { gql } from '@apollo/client'

export const GET_AGENT_PLANS = gql`
    query AGENT($agentId: ID!)
    {
        agent(id: $agentId)
        {
            success
            agent 
            {
                _id
                name
                age
                gender
                status
                emoji
                description
                agentDescription 
                {
                    eyes
                    hair
                    clothing
                    distinguishingFeatures
                    portraitFilename
                    iconFilename
                    resizedIconFilename
                    chibiFilename
                    resizedChibiFilename
                }
                currentActivity
                {
                    timeFrame
                    startDateTime
                    endDateTime
                    description
                }
                plannedActivities
                {
                    description
                    timeFrame
                    startDateTime
                    endDateTime
                }
                goals 
                {
                    title
                    timeFrame
                    description
                }
                agentLocation 
                {
                    currentScenarioId
                    location 
                    {
                        name
                        _id
                        resizedImageInteriorFilename
                    }
                    scenario 
                    {
                        _id
                        name
                        currentDateTime
                        imageFilename
                    }
                }
            }
        }
    }`