import React from 'react';
import GameWindowSize from '../../GameWindowSize';
import OverworldLocations from './OverworldLocations';
import OverworldCharacters from './OverworldCharacters';
import OverworldLabel from './OverworldLabel';
import { ProfileButton } from '../../Profile/ProfileButton';
import { useEffect } from 'react';

const OverworldBackground = (props) => {
    const gameWindowSize = GameWindowSize({ width: 2048, height: 1024 });

    let currentY = props.scenario.locations.length >= 9
        ? gameWindowSize.height * 0.72
        : gameWindowSize.height * 0.6;

    const backgroundStyle = {
        position: 'absolute',
        zIndex: 10  // Background layer
    };

    return (
        <div className="relative w-full h-full"
            style={backgroundStyle}>
            <div className="relative">
                <img
                    src={`https://dyifmflum502e.cloudfront.net/${props.scenario.imageFilename}`}
                    width={gameWindowSize.width}
                    height={gameWindowSize.height}
                    className="absolute z-10"
                    alt="Background"
                />

                <div className="absolute top-8 left-8 z-30">
                    <ProfileButton />
                </div>

                <OverworldLabel scenario={props.scenario} />
            </div>

            <OverworldLocations
                scenario={props.scenario}
                width={gameWindowSize.width}
                height={gameWindowSize.height}
                widthMultiplier={gameWindowSize.widthMultiplier}
                heightMultiplier={gameWindowSize.heightMultiplier}
            />

            <OverworldCharacters
                scenario={props.scenario}
                width={gameWindowSize.width}
                height={gameWindowSize.height}
                widthMultiplier={gameWindowSize.widthMultiplier}
                heightMultiplier={gameWindowSize.heightMultiplier}
                y={currentY}
            />
        </div>
    );
}

export default OverworldBackground;