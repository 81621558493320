import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';

const OverworldLabel = ({ scenario }) => {
    const navigate = useNavigate();

    if (!scenario) return null;

    const handleScenarioOpen = () => {
        navigate(`/yap/${scenario._id}`);
    };

    return (
        <div className="absolute inset-x-0 top-16 flex justify-center z-30">
            <Button
                variant="secondary"
                size="lg"
                className="bg-white/90 hover:bg-white/95 shadow-lg"
                onClick={handleScenarioOpen}
            >
                <h3 className="text-xl font-bold">{scenario.name}</h3>
            </Button>
        </div>
    );
};

export default OverworldLabel;