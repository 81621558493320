import React from 'react';
import { InteriorLayout } from './InteriorLayout';
import { InteriorCharacters } from './InteriorCharacters';
import GameWindowSize from '../../GameWindowSize';

export const InteriorBackground = ({ location }) => {
    const gameWindowSize = GameWindowSize({ width: 2048, height: 1024 });
    const characterY = gameWindowSize.height * 0.22;

    return (
        <InteriorLayout location={location}>
            <img
                src={`https://dyifmflum502e.cloudfront.net/${location.resizedImageInteriorFilename}`}
                width={gameWindowSize.width}
                height={gameWindowSize.height}
                className="absolute inset-0 z-0"
                alt={location.name}
            />

            <InteriorCharacters
                agents={location.agents}
                windowSize={gameWindowSize}
                baseY={characterY}
            />
        </InteriorLayout>
    );
};