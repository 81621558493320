import { gql } from '@apollo/client'

export const GET_SCENARIO_ARTIFICE = gql`
    query Scenario($scenarioId: ID!)
    {
        scenario(id: $scenarioId) 
        {
            success
            scenario 
            {
                _id
                imageFilename
                name
                description
                currentDateTime
                
                artifice
                {
                    _id
                    date_created
                    image_url
                    prompt
                    negative_prompt
                    hashtags
                    caption
                    scenario
                    {
                        name
                        imageFilename
                    }
                    artists
                    {
                        _id
                        name
                        agentDescription
                        {
                            eyes
                            hair
                            clothing
                            distinguishingFeatures
                            portraitFilename
                            iconFilename
                            resizedIconFilename
                            chibiFilename
                            resizedChibiFilename
                        }
                    }
                    caption
                }
            }
        }
    }`