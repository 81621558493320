import { useState, useEffect } from 'react';

const GameWindowSize = (defaultSize) => {
    const [size, setSize] = useState({
        width: defaultSize.width,
        height: defaultSize.height,
        widthMultiplier: 1,
        heightMultiplier: 1
    });

    useEffect(() => {
        const updateSize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            setSize({
                width,
                height,
                widthMultiplier: width / defaultSize.width,
                heightMultiplier: height / defaultSize.height
            });
        };

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [defaultSize]);

    return size;
};

export default GameWindowSize;