import React from 'react';
import { Dialog, DialogContent } from './ui/dialog';
import SocialLinksCard from './SocialLinksCard';

export const CharacterProfile = ({ agent, isOpen, onClose }) => {
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-md">
                <SocialLinksCard agent={agent} />
            </DialogContent>
        </Dialog>
    );
};