import React from 'react';
import { Brain } from 'lucide-react';
import { useQuery } from '@apollo/client';
import { GET_AGENT_PROFILE } from './GetAgentProfile';
import ReactLoading from 'react-loading';

export const ProfileHeader = ({ agentId }) => {

    const { loading, error, data } = useQuery(GET_AGENT_PROFILE, {
        variables: { agentId: agentId }
    });

    if (loading) {
        return (
            <ReactLoading type="spinningBubbles" color="#444" />
        );
    }
    else if (error) {
        return (
            <p>Something went wrong...</p>
        );
    }
    else {
        return (
            <div className="bg-white shadow mb-6 rounded-lg">
                <div className="p-6">
                    <div className="flex items-center gap-4">
                        <div className="w-20 h-20 bg-emerald-100 rounded-full flex items-center justify-center">
                            <Brain className="w-12 h-12 text-emerald-500" />
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold">{data.agent.agent.name}</h1>
                            <p className="text-gray-600">
                                Stream of consciousness for {data.agent.agent.name}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}