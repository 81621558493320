import React from 'react';
import Chirp from './Chirp';

const Feed = ({ posts, showReplies = false }) => (
    <div className="divide-y divide-gray-200">
        {posts.map(post => (
            <Chirp key={post.id} post={post} showReplies={showReplies} />
        ))}
    </div>
);

export default Feed