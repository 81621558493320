import React from 'react';
import { useNavigate } from 'react-router-dom';
import { User, MapPin, ArrowLeft } from 'lucide-react';

const QuillProfile = ({ agent }) => {
    const navigate = useNavigate();

    const imageUrl = agent.agentDescription?.portraitFilename
        ? `https://dyifmflum502e.cloudfront.net/${agent.agentDescription.portraitFilename}`
        : '/api/placeholder/128/224';

    const background = agent.agentLocation?.scenario?.imageFilename;

    return (
        <div className="w-full bg-[#001935]">
            {/* Header with back button and basic info */}
            <div className="sticky top-0 z-10 bg-[#001935]/80 backdrop-blur-md border-b border-gray-700">
                <div className="flex items-center p-4 max-w-3xl mx-auto">
                    <button
                        className="p-2 rounded-full hover:bg-[#203354] text-gray-100"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowLeft size={20} />
                    </button>
                    <div className="ml-6">
                        <h2 className="font-bold text-xl text-gray-100">{agent.name}</h2>
                        <span className="text-gray-400 text-sm">
                            {agent.journalEntries?.length || 0} quills
                        </span>
                    </div>
                </div>
            </div>

            {/* Cover image */}
            <div className="h-48 relative overflow-hidden">
                {background ? (
                    <img
                        src={`https://dyifmflum502e.cloudfront.net/${background}`}
                        alt="Profile background"
                        className="w-full h-full object-cover"
                    />
                ) : (
                    <div className="w-full h-full bg-gradient-to-r from-blue-500 to-purple-500" />
                )}
            </div>

            {/* Profile section */}
            <div className="max-w-3xl mx-auto px-4">
                <div className="flex justify-between">
                    <div className="relative -mt-20">
                        {imageUrl ? (
                            <div className="w-32 h-32 rounded-full border-4 border-[#001935] overflow-hidden relative">
                                <div className="absolute inset-0 bg-gradient-to-br from-indigo-700 via-slate-900 to-purple-900" />
                                <img
                                    src={imageUrl}
                                    alt={agent.name}
                                    className="absolute inset-0 w-full h-full object-contain"
                                />
                            </div>
                        ) : (
                            <div className="w-32 h-32 rounded-full border-4 border-[#001935] bg-gray-800 flex items-center justify-center">
                                <User size={48} className="text-gray-400" />
                            </div>
                        )}
                    </div>
                    <button className="px-4 py-2 rounded-full border border-gray-600 font-bold text-gray-100 hover:bg-[#203354] mt-2">
                        Edit profile
                    </button>
                </div>

                {/* Profile info */}
                <div className="mt-4 mb-6">
                    <h2 className="text-xl font-bold text-gray-100">{agent.name}</h2>
                    {agent.agentLocation?.scenario?.name && (
                        <div className="flex items-center text-gray-400 mt-1">
                            <MapPin size={16} className="mr-1" />
                            <span className="text-sm">{agent.agentLocation.scenario.name}</span>
                        </div>
                    )}
                    <p className="mt-3 text-gray-300">{agent.agentDescription?.title}</p>
                </div>
            </div>
        </div>
    );
};

export default QuillProfile;