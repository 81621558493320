import React from 'react';
import { MessageCircle, Heart, Share2, UserCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const Chirp = ({ post, showReplies = false, showOriginal = true }) => {

    const navigate = useNavigate();

    const isReply = post.reply_to != null;

    const imageUrl = post.author.avatar
        ? `https://dyifmflum502e.cloudfront.net/${post.author.avatar}`
        : '/api/placeholder/128/224';

    return (
        <div className={`p-4 ${isReply ? 'border-b border-gray-200' : 'border-b border-gray-200'}`}>
            {/* Show original tweet if this is a reply */}
            {isReply && showOriginal && (
                <div className="mb-3 pl-3 border-l-2 border-gray-200">
                    <div className="flex items-start space-x-3"
                        onClick={() => navigate(`/chirp/${post.reply_to._id}`)}>
                        <div className="flex-shrink-0">
                            {post.reply_to.author.avatar ? (
                                <img
                                    src={`https://dyifmflum502e.cloudfront.net/${post.reply_to.author.avatar}`}
                                    alt={post.reply_to.author.username}
                                    className="w-8 h-8 rounded-full"
                                />
                            ) : (
                                <UserCircle className="w-8 h-8 text-gray-400" />
                            )}
                        </div>
                        <div className="flex-grow">
                            <div className="flex items-center space-x-2">
                                <span className="font-medium">{post.reply_to.author.username}</span>
                            </div>
                            <p className="mt-1 text-gray-600 text-sm">{post.reply_to.content}</p>
                        </div>
                    </div>
                </div>
            )}

            {/* Current tweet */}
            <div className="flex items-start space-x-3"
                onClick={() => navigate(`/chirp/${post._id}`)}>
                <div className="flex-shrink-0">
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt={post.author.username}
                            className="w-10 h-10 rounded-full"
                        />
                    ) : (
                        <UserCircle className="w-10 h-10 text-gray-400" />
                    )}
                </div>

                <div className="flex-grow">
                    <div className="flex items-center space-x-2">
                        <span className="font-medium">{post.author.username}</span>
                        <span className="text-sm text-gray-500">{post.time}</span>
                    </div>

                    <p className="mt-1 text-gray-800">{post.content}</p>

                    {post.imageUrl && (
                        <div className="mt-2 rounded-lg overflow-hidden aspect-square">
                            <img
                                src={`https://dyifmflum502e.cloudfront.net/${post.imageUrl}`}
                                alt="Post media content"
                                className="w-full h-full object-cover"
                            />
                        </div>
                    )}

                    <div className="mt-2 flex items-center space-x-6">
                        <button className="flex items-center space-x-2 text-gray-500 hover:text-gray-700">
                            <Heart className="w-4 h-4" />
                            <span className="text-sm">{post.stats?.like_count || 0}</span>
                        </button>
                        <button className="flex items-center space-x-2 text-gray-500 hover:text-gray-700">
                            <MessageCircle className="w-4 h-4" />
                            <span className="text-sm">{post.stats?.reply_count || 0}</span>
                        </button>
                        <button className="flex items-center space-x-2 text-gray-500 hover:text-gray-700">
                            <Share2 className="w-4 h-4" />
                        </button>
                    </div>
                </div>
            </div>

            {showReplies && post.replies && post.replies.map(reply => (
                <Chirp key={reply.id} post={reply} showReplies={false} showOriginal={false} />
            ))}
        </div>
    );
}

export default Chirp