import { gql } from '@apollo/client'

export const GET_ARTIFACT = gql`
    query ARTIFACT($artifactId: ID!)
    {
        artifact(id: $artifactId)
        {
            success
            artifact
            {
                _id
                date_created
                image_url
                prompt
                negative_prompt
                hashtags
                caption
                scenario
                {
                    name
                    imageFilename
                }
                artists
                {
                    _id
                    name
                    agentDescription
                    {
                        eyes
                        hair
                        clothing
                        distinguishingFeatures
                        portraitFilename
                        iconFilename
                        resizedIconFilename
                        chibiFilename
                        resizedChibiFilename
                    }
                }
                caption
                scenario
                {
                    name
                    imageFilename
                }
            }
        }
    }`