import { Button } from '../../components/ui/button';

export const ConversationCard = ({
    isSummary,
    showNextButton,
    dialogueIndex,
    dialogue,
    summary,
    width,
    height,
    handleNext
}) => {
    const border = 100;
    const left = border;
    const top = height * 0.75;
    const cardWidth = width - (left * 2);
    const cardHeight = height - top - (border / 2);

    return (
        <div
            className="absolute rounded-lg border-2 border-white bg-black/75 p-5 z-10"
            style={{
                left: `${left}px`,
                top: `${top}px`,
                width: `${cardWidth}px`,
                height: `${cardHeight}px`
            }}
        >
            {!isSummary && (
                <h2 className="text-2xl font-bold text-white mb-4">
                    {dialogue[dialogueIndex].agentName}:
                </h2>
            )}
            <h2 className="text-2xl text-white">
                {isSummary ? summary : dialogue[dialogueIndex].text}
            </h2>
            {showNextButton && (
                <Button
                    onClick={handleNext}
                    variant="secondary"
                    size="sm"
                    className="absolute right-4 bottom-2 z-20"
                >
                    Next
                </Button>
            )}
        </div>
    );
};