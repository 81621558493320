import React, { useState } from 'react';
import { Bell, Menu, MessageCircle, Search, User, Bird, Brush, Award } from 'lucide-react';

const YapScreen = ({ scenario }) => {
    const [activeTab, setActiveTab] = useState('info');

    // Format description paragraphs
    const formatDescription = (text) => {
        return text.split('\n\n').map((paragraph, index) => (
            <p key={index} className="mb-4">{paragraph}</p>
        ));
    };

    const scenarioData = {
        socialMedia: [
            {
                name: "Chirpy",
                description: "Short-form social messaging platform",
                icon: Bird,
                color: 'text-sky-500',
                hoverColor: 'hover:bg-sky-50',
                url: `/scenariochirpy/${scenario._id}`
            },
            {
                name: "Artifice",
                description: "Image and art sharing social platform",
                icon: Brush,
                color: 'text-violet-500',
                hoverColor: 'hover:bg-violet-50',
                url: `/scenarioartifice/${scenario._id}`
            }
        ]
    };

    // Function to get quest difficulty badge color
    const getDifficultyColor = (difficulty) => {
        switch (difficulty) {
            case 'easy':
                return 'bg-green-100 text-green-800';
            case 'medium':
                return 'bg-yellow-100 text-yellow-800';
            case 'hard':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    // Function to get timeframe badge color
    const getTimeframeColor = (timeframe) => {
        switch (timeframe) {
            case 'short-term':
                return 'bg-blue-100 text-blue-800';
            case 'long-term':
                return 'bg-purple-100 text-purple-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    return (
        <div className="flex flex-col h-screen bg-gray-100">
            {/* Top Navigation Bar */}
            <header className="bg-blue-600 text-white p-4 shadow-md">
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <Menu className="mr-4" />
                        <h1 className="text-xl font-bold">Pixel Valley</h1>
                    </div>
                    <div className="flex items-center space-x-4">
                        <Search />
                        <Bell />
                        <User />
                    </div>
                </div>
            </header>

            {/* Scenario Header with Background Image */}
            <div
                className="p-8 shadow-sm relative bg-cover bg-center text-white overflow-hidden"
                style={{
                    height: "250px"
                }}
            >
                {scenario.imageFilename ? (
                    <div
                        className="absolute inset-0"
                        style={{
                            backgroundImage: `url(https://dyifmflum502e.cloudfront.net/${scenario.imageFilename})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            filter: 'brightness(0.7)'
                        }}
                    ></div>
                ) : (
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-purple-900"></div>
                )}
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-60"></div>
                <div className="relative z-10 flex flex-col justify-end h-full">
                    <h2 className="text-4xl font-bold mb-2 text-white">{scenario.name}</h2>
                    <p className="text-gray-200">{scenario.currentDateTime}</p>
                </div>
            </div>

            {/* Tab Navigation */}
            <div className="flex border-b">
                <button
                    className={`px-4 py-2 ${activeTab === 'info' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                    onClick={() => setActiveTab('info')}
                >
                    Information
                </button>
                <button
                    className={`px-4 py-2 ${activeTab === 'news' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                    onClick={() => setActiveTab('news')}
                >
                    Newspapers
                </button>
                <button
                    className={`px-4 py-2 ${activeTab === 'social' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                    onClick={() => setActiveTab('social')}
                >
                    Social Media
                </button>
                <button
                    className={`px-4 py-2 ${activeTab === 'quests' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                    onClick={() => setActiveTab('quests')}
                >
                    Quests
                </button>
            </div>

            {/* Main Content Area */}
            <div className="flex-1 overflow-y-auto p-4">
                {activeTab === 'info' && (
                    <div className="bg-white rounded-lg shadow p-4">
                        <h3 className="text-lg font-semibold mb-2">About {scenario.name}</h3>
                        <div className="text-gray-700">
                            {formatDescription(scenario.description)}
                        </div>
                    </div>
                )}

                {activeTab === 'news' && (
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold">Available Newspapers</h3>
                        {scenario.newspapers.map((paper, index) => (
                            <a
                                key={index}
                                href={`/newspaper/${paper._id}`}
                                className="bg-white rounded-lg shadow p-4 block hover:bg-blue-50 cursor-pointer transition"
                            >
                                <h4 className="font-medium text-blue-600">{paper.name}</h4>
                                <p className="text-gray-600">{paper.description}</p>
                            </a>
                        ))}
                    </div>
                )}

                {activeTab === 'social' && (
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold">Social Media Platforms</h3>
                        {scenarioData.socialMedia.map((app, index) => {
                            const IconComponent = app.icon;
                            return (
                                <a
                                    key={index}
                                    href={app.url}
                                    className={`bg-white rounded-lg shadow p-4 flex items-center cursor-pointer transition ${app.hoverColor}`}
                                >
                                    <div className={`h-10 w-10 rounded-md flex items-center justify-center mr-4 ${app.color}`}>
                                        <IconComponent size={24} />
                                    </div>
                                    <div>
                                        <h4 className="font-medium">{app.name}</h4>
                                        <p className="text-gray-600">{app.description}</p>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                )}

                {activeTab === 'quests' && (
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold">Available Quests</h3>
                        {scenario.quests && scenario.quests.map((quest, index) => (
                            <div
                                key={index}
                                className="bg-white rounded-lg shadow p-4 block transition"
                            >
                                <div className="flex justify-between items-start mb-2">
                                    <h4 className="font-medium text-blue-600 text-lg">{quest.title}</h4>
                                    <div className="flex items-center">
                                        <span className={`text-xs px-2 py-1 rounded-full mr-2 ${getDifficultyColor(quest.difficulty)}`}>
                                            {quest.difficulty.charAt(0).toUpperCase() + quest.difficulty.slice(1)}
                                        </span>
                                        <span className={`text-xs px-2 py-1 rounded-full ${getTimeframeColor(quest.timeframe)}`}>
                                            {quest.timeframe.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                        </span>
                                    </div>
                                </div>

                                <p className="text-gray-700 mb-3">{quest.description}</p>

                                <div className="flex items-center justify-between mt-2 text-sm">
                                    <div>
                                        <span className="text-gray-500">Suggested approach: </span>
                                        <span className="text-gray-700">{quest.suggested_approach}</span>
                                    </div>
                                </div>

                                <div className="flex items-center mt-3 pt-3 border-t border-gray-100">
                                    <Award className="text-yellow-500 mr-2" size={16} />
                                    <span className="font-medium">Reward: {quest.reward_text}</span>
                                </div>

                                {quest.completed && (
                                    <div className="mt-2 text-green-600 font-medium flex items-center">
                                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Completed
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default YapScreen;