import React, { useState } from 'react';
import { Star, Users, Trophy, Folder } from 'lucide-react';
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@apollo/client";
import { CREATE_SCENARIO_REQUEST } from '../Queries/CreateScenarioRequest';
import { Button } from "../components/ui/button";
import { useToast } from "../components/ui/use-toast";
import LoginButton from '../Profile/LoginButton';
import { useNavigate } from 'react-router-dom';

const ImprovedLandingPage = () => {
    const [prompt, setPrompt] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const { toast } = useToast();
    const [createScenarioRequest] = useMutation(CREATE_SCENARIO_REQUEST);
    const navigate = useNavigate();

    const demoScenarios = [
        {
            prompt: "Pirate Island",
            title: "Tortuga",
            id: "662555b4a6e2b73baeb97e5e",
            description: "A bustling and lively community located on a remote island in the Caribbean",
            largeImage: "img/portfolio/Tortuga-lg.png",
            smallImage: "img/portfolio/Tortuga-sm.png"
        },
        {
            prompt: "Jane Austin Village",
            title: "Pemberley",
            id: "66004b13006cd24fff3ea55a",
            description: "The cobblestone streets are lined with charming cottages adorned with colorful flower gardens",
            largeImage: "img/portfolio/Pemberley-lg.png",
            smallImage: "img/portfolio/Pemberley-sm.png"
        },
        {
            prompt: "Cyberpunk Dystopia",
            title: "Neo Tokyo",
            id: "65ff27fb6e43ac4559f147fc",
            description: "A marvel of advanced technology and neon-lit skyscrapers that pierce the night sky",
            largeImage: "img/portfolio/NeoTokyo-lg.png",
            smallImage: "img/portfolio/NeoTokyo-sm.png"
        }
    ];

    const handleScenarioCreate = async (e) => {
        e.preventDefault();

        if (!prompt.trim()) {
            toast({
                title: "Error",
                description: "Please enter a scenario description",
                variant: "destructive",
            });
            return;
        }

        if (!isAuthenticated) {
            loginWithRedirect({
                appState: {
                    returnTo: window.location.pathname,
                    scenarioText: prompt.trim()
                }
            });
            return;
        }

        setIsGenerating(true);

        try {
            const response = await createScenarioRequest({
                variables: { scenarioDescription: prompt.trim() }
            });

            toast({
                title: "Success!",
                description: "Your scenario has been created! Check your profile to see it.",
                variant: "success",
            });

            setPrompt('');
        } catch (error) {
            console.error('Error creating scenario:', error);
            toast({
                title: "Creation Failed",
                description: "Unable to create your scenario. Please try again.",
                variant: "destructive",
            });
        } finally {
            setIsGenerating(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
            {/* Hero Section */}
            <div
                className="relative pt-20 sm:pt-32 pb-12 sm:pb-20 px-4 bg-cover"
                style={{
                    backgroundImage: 'url("/img/Screenshot.png")',
                    backgroundPosition: 'center center'
                }}
            >
                {/* Dark overlay for better text contrast */}
                <div className="absolute inset-0 bg-black bg-opacity-60"></div>
                <div className="absolute top-4 right-4 z-20">
                    {!isAuthenticated ? (
                        <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-1 sm:gap-3">
                            <Button
                                onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
                                className="bg-blue-500 hover:bg-blue-600 text-white text-xs sm:text-sm py-1.5 px-3 sm:px-4 h-8 sm:h-10 min-w-[100px]"
                            >
                                Create Account
                            </Button>
                            <LoginButton />
                        </div>
                    ) : (
                        <Button
                            onClick={() => window.location.href = '/userprofile'}
                            className="bg-blue-500 hover:bg-blue-600 text-white flex items-center gap-1.5 text-xs sm:text-sm py-1.5 px-3 sm:px-4 h-8 sm:h-10"
                        >
                            <Folder className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
                            My Scenarios
                        </Button>
                    )}
                </div>
                <div className="max-w-6xl mx-auto relative z-10">
                    <div className="text-center mb-8 sm:mb-12">
                        {/* User Actions */}


                        <div className="inline-block bg-blue-500 text-white px-3 py-1 sm:px-4 sm:py-2 rounded-full mb-4 sm:mb-6 text-sm sm:text-base">
                            ✨ AI-Powered Game Scenarios
                        </div>
                        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6 text-white px-2">
                            Your Imagination, Instantly Playable
                        </h1>
                        <p className="text-lg sm:text-xl text-white mb-6 sm:mb-8 max-w-2xl mx-auto px-2">
                            Type any scenario you can imagine, and watch as it transforms into a fully playable Pixel Valley adventure. No coding needed - just your creativity.
                        </p>

                        {/* Interactive Demo */}
                        <div className="max-w-4xl mx-auto bg-gray-800 p-4 sm:p-8 rounded-xl mb-8">
                            <form onSubmit={handleScenarioCreate}>
                                <div className="flex gap-4">
                                    <input
                                        type="text"
                                        placeholder="Describe your dream scenario... (e.g. A spooky ninja fortress)"
                                        className="flex-1 h-12 px-4 rounded-lg bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm sm:text-base"
                                        value={prompt}
                                        onChange={(e) => setPrompt(e.target.value)}
                                    />
                                    <Button
                                        type="submit"
                                        disabled={isGenerating || !prompt.trim()}
                                        className={`h-12 px-6 rounded-lg font-semibold flex items-center justify-center gap-2 transition-all ${prompt.trim() && !isGenerating
                                            ? 'bg-blue-500 hover:bg-blue-600 text-white'
                                            : 'bg-gray-200 text-gray-500'
                                            }`}
                                    >
                                        {isGenerating ? (
                                            <>
                                                <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent" />
                                                <span className="text-white">Creating...</span>
                                            </>
                                        ) : (
                                            <>
                                                <Star
                                                    className={`w-5 h-5 transition-colors duration-200 ${prompt.trim()
                                                        ? 'text-yellow-300 fill-yellow-300'
                                                        : 'text-gray-400'
                                                        }`}
                                                />
                                                <span className="text-sm sm:text-base whitespace-nowrap">{isAuthenticated ? 'Generate' : 'Login to Generate'}</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </form>
                            {isGenerating && (
                                <div className="text-left p-4 bg-gray-700 rounded-lg animate-fade-in mt-4">
                                    <div className="flex items-center gap-2 mb-2">
                                        <Star className="w-5 h-5 text-yellow-400" />
                                        <span className="text-sm text-gray-400">Generating your adventure...</span>
                                    </div>
                                    <div className="h-2 bg-gray-600 rounded-full overflow-hidden">
                                        <div className="w-2/3 h-full bg-blue-500 rounded-full animate-pulse"></div>
                                    </div>
                                </div>
                            )}
                        </div>


                    </div>
                </div>
            </div>

            {/* How It Works */}
            <div className="max-w-6xl mx-auto px-4 py-12 sm:py-20">
                <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">How It Works</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="text-center p-4 sm:p-6">
                        <div className="w-12 h-12 sm:w-16 sm:h-16 bg-blue-500 rounded-full flex items-center justify-center mx-auto mb-4">
                            <Star className="w-6 h-6 sm:w-8 sm:h-8" />
                        </div>
                        <h3 className="text-lg sm:text-xl font-semibold mb-2">1. Enter Your Prompt</h3>
                        <p className="text-gray-400 text-sm sm:text-base">Type any scenario you can imagine - from magical forests to sci-fi adventures</p>
                    </div>
                    <div className="text-center p-4 sm:p-6">
                        <div className="w-12 h-12 sm:w-16 sm:h-16 bg-purple-500 rounded-full flex items-center justify-center mx-auto mb-4">
                            <Trophy className="w-6 h-6 sm:w-8 sm:h-8" />
                        </div>
                        <h3 className="text-lg sm:text-xl font-semibold mb-2">2. AI Generates Your World</h3>
                        <p className="text-gray-400 text-sm sm:text-base">Our AI creates a unique, playable pixel world based on your description</p>
                    </div>
                    <div className="text-center p-4 sm:p-6">
                        <div className="w-12 h-12 sm:w-16 sm:h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-4">
                            <Folder className="w-6 h-6 sm:w-8 sm:h-8" />
                        </div>
                        <h3 className="text-lg sm:text-xl font-semibold mb-2">3. Play & Collect</h3>
                        <p className="text-gray-400 text-sm sm:text-base">Play instantly and build your collection of unique scenarios</p>
                    </div>
                </div>
            </div>

            {/* Example Scenarios */}
            <div className="max-w-6xl mx-auto px-4 py-12 sm:py-20">
                <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8 sm:mb-12">Examples From Our Community</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
                    {demoScenarios.map((scenario, i) => (
                        <div
                            key={i}
                            onClick={() => navigate(`/scenario/${scenario.id}`)}
                            className="bg-gray-800 rounded-xl overflow-hidden hover:transform hover:scale-105 transition-all cursor-pointer"
                        >
                            <img
                                src={scenario.smallImage}
                                alt="Scenario preview"
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4 sm:p-6">
                                <div className="text-sm text-gray-400 mb-2">Prompt:</div>
                                <p className="text-gray-300 italic mb-4 text-sm sm:text-base">"{scenario.prompt}"</p>
                                <h3 className="text-lg sm:text-xl font-semibold mb-2">{scenario.title}</h3>
                                <p className="text-gray-400 text-sm sm:text-base">{scenario.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* CTA Section */}
            <div className="max-w-4xl mx-auto px-4 py-12 sm:py-20 text-center">
                <h2 className="text-2xl sm:text-4xl font-bold mb-4 sm:mb-6">Start Creating Your Adventures</h2>
                <p className="text-lg sm:text-xl text-gray-300 mb-6 sm:mb-8">
                    Your imagination is the only limit. Create your first scenario now!
                </p>
                <button
                    onClick={() => window.location.href = '/userprofile'}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-6 sm:px-8 py-3 sm:py-4 rounded-lg text-base sm:text-lg font-semibold transition-all transform hover:scale-105 flex items-center gap-2 mx-auto"
                >
                    <Star className="w-5 h-5 sm:w-6 sm:h-6" />
                    Create Your First Scenario
                </button>
            </div>
        </div>
    );
};

export default ImprovedLandingPage;