import React, { useMemo } from 'react';
import { InteriorCharacter } from './InteriorCharacter';

export const InteriorCharacters = ({ agents, windowSize, baseY }) => {
    const characters = useMemo(() => {
        if (agents.length === 0) return [];

        const characterWidth = 512 * windowSize.widthMultiplier;
        const totalWidth = characterWidth * agents.length;
        const startX = (windowSize.width / 2) - (totalWidth / 2);

        return agents.map((agent, index) => ({
            agent,
            x: startX + (characterWidth * index),
            y: baseY
        }));
    }, [agents, windowSize, baseY]);

    return (
        <>
            {characters.map(({ agent, x, y }) => (
                <InteriorCharacter
                    key={agent._id}
                    agent={agent}
                    position={{ x, y }}
                    scale={{
                        width: windowSize.widthMultiplier * 0.83,
                        height: windowSize.heightMultiplier * 0.83
                    }}
                />
            ))}
        </>
    );
};