import React from 'react';
import Quills from './Quills';
import QuillProfile from './QuillProfile';

const QuillScreen = ({ agent }) => {
    return (
        <div className="bg-[#001935]">
            {/* Profile */}
            <QuillProfile agent={agent} />

            {/* Posts Feed */}
            <Quills agent={agent} showReplies={false} />
        </div>
    );
};

export default QuillScreen;