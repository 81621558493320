import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ArtificeHeader = ({ agent, scenario }) => {
    const navigate = useNavigate();
    const background = scenario.imageFilename;

    const imageUrl = agent?.agentDescription?.portraitFilename
        ? `https://dyifmflum502e.cloudfront.net/${agent?.agentDescription?.portraitFilename}`
        : '/api/placeholder/128/224';

    return (
        <div>
            {/* Navigation Bar */}
            <div className="bg-gray-900">
                <div className="max-w-6xl mx-auto px-4 py-4">
                    <div className="flex items-center">
                        <button
                            onClick={() => navigate(-1)}
                            className="flex items-center text-white hover:text-gray-300 transition-colors"
                        >
                            <ArrowLeft className="h-6 w-6" />
                        </button>
                        <span className="ml-4 text-xl font-semibold text-white">Artifice</span>
                    </div>
                </div>
            </div>

            <div className="relative mb-6">
                {/* Banner Image */}
                <div className="h-48 bg-gradient-to-r from-blue-900 to-purple-900">
                    {background ? (
                        <img
                            src={`https://dyifmflum502e.cloudfront.net/${background}`}
                            alt="Profile background"
                            className="w-full h-full object-cover opacity-80"
                        />
                    ) : (
                        <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-purple-900" />
                    )}
                </div>

                {agent ? (
                    <div className="max-w-6xl mx-auto px-4">
                        {/* Profile Basic Info */}
                        <div className="relative -mt-16 pb-4">
                            <div className="flex items-start">
                                <div className="relative">
                                    <Avatar className="h-32 w-32 ring-4 ring-gray-900">
                                        <div className="absolute inset-0 bg-gradient-to-br from-gray-800 via-gray-900 to-black rounded-full" />
                                        <AvatarImage
                                            src={imageUrl}
                                            alt={agent?.name}
                                            className="object-cover relative z-10"
                                        />
                                        <AvatarFallback className="relative z-10">{agent?.name?.[0] ?? 'A'}</AvatarFallback>
                                    </Avatar>
                                </div>
                                <div className="ml-4 mt-4 flex-1">
                                    <h1 className="text-4xl font-bold text-white">{agent?.name}</h1>
                                    <p className="text-gray-400">{agent?.bio}</p>
                                    <p className="text-gray-300 mt-2">{agent?.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div />
                )}

            </div>
        </div>
    );
};

export default ArtificeHeader;