export const ConversationCharacter = ({
    isSummary,
    dialogueIndex,
    dialogue,
    agent,
    x,
    y,
    widthMultiplier,
    heightMultiplier
}) => {
    let isFiltered = !isSummary;
    if (dialogueIndex < dialogue.length) {
        const currentDialogue = dialogue[dialogueIndex];
        const isCurrentCharacter = agent.name === currentDialogue.agentName;
        isFiltered = !isSummary && !isCurrentCharacter;
    }

    return (
        <div
            className="absolute"
            style={{
                left: x,
                top: y - 16,
                width: 512 * widthMultiplier,
                height: 768 * heightMultiplier
            }}
        >
            <img
                src={`https://dyifmflum502e.cloudfront.net/${agent.agentDescription.portraitFilename}`}
                className={`absolute z-10 top-4 ${isFiltered ? 'grayscale brightness-50' : ''
                    }`}
                width={512 * widthMultiplier}
                height={768 * heightMultiplier}
                alt={agent.name}
            />
        </div>
    );
};