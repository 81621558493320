import React, { useState } from 'react';
import { Card, CardContent } from '../components/ui/card';
import { Avatar } from '../components/ui/avatar';
import { Button } from '../components/ui/button';
import { User, Heart, MessageCircle, Repeat2 } from 'lucide-react';

const Quills = ({ agent }) => {
    const [likes, setLikes] = useState({});
    const [reblogs, setReblogs] = useState({});

    console.log('Quills rendering with entries:', agent?.journalEntries); // Debug log

    if (!agent?.journalEntries?.length) {
        return (
            <div className="max-w-3xl mx-auto px-4 py-8 text-gray-400">
                No entries found
            </div>
        );
    }

    return (
        <div className="w-full py-6">
            <div className="max-w-3xl mx-auto px-4">
                <div className="space-y-6">
                    {agent.journalEntries.map((entry) => (
                        <Card key={entry._id} className="bg-[#203354] border-0">
                            <CardContent className="p-4">
                                {/* Post Header */}
                                <div className="flex items-center space-x-2 mb-4">
                                    <Avatar className="h-8 w-8">
                                        {agent.agentDescription?.iconFilename ? (
                                            <img
                                                src={`https://dyifmflum502e.cloudfront.net/${agent.agentDescription.resizedChibiFilename}`}
                                                alt={agent.displayName}
                                                className="object-cover"
                                            />
                                        ) : (
                                            <User className="h-4 w-4" />
                                        )}
                                    </Avatar>
                                    <div>
                                        <div className="font-bold text-sm text-gray-100">{agent.displayName}</div>
                                        <div className="text-gray-400 text-xs">{entry.dateTime}</div>
                                    </div>
                                </div>

                                {/* Post Content */}
                                {entry.summary && (
                                    <h3 className="text-xl font-bold mb-4 text-gray-100">{entry.summary}</h3>
                                )}
                                <div className="space-y-4 mb-6">
                                    {entry.text.split('\n\n').map((paragraph, idx) => (
                                        paragraph.trim() && (
                                            <p key={idx} className="text-gray-300 text-sm">
                                                {paragraph}
                                            </p>
                                        )
                                    ))}
                                </div>

                                {/* Post Footer */}
                                <div className="flex items-center justify-between pt-4 border-t border-gray-700">
                                    <div className="flex items-center space-x-4">
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            className="text-gray-400 hover:text-gray-100"

                                        >
                                            <Heart className={`h-4 w-4 ${likes[entry._id] ? 'fill-current text-red-500' : ''}`} />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            className="text-gray-400 hover:text-gray-100"
                                        >
                                            <MessageCircle className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            className="text-gray-400 hover:text-gray-100"

                                        >
                                            <Repeat2 className={`h-4 w-4 ${reblogs[entry._id] ? 'text-green-500' : ''}`} />
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Quills;