import React from 'react';
import { Helmet } from 'react-helmet';

// For AI Artifacts
export const ArtifactMeta = ({ art }) => {
    const imageUrl = `https://dyifmflum502e.cloudfront.net/${art.image_url}`;
    const artistNames = art.artists.map(artist => artist.name).join(', ');

    return (
        <Helmet>
            {/* Basic Meta Tags */}
            <title>{`${artistNames} - AI Artwork`}</title>
            <meta name="description" content={art.caption} />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="article" />
            <meta property="og:title" content={`AI Artwork by ${artistNames}`} />
            <meta property="og:description" content={art.caption} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:image:alt" content="AI Generated Artwork" />

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={`AI Artwork by ${artistNames}`} />
            <meta name="twitter:description" content={art.caption} />
            <meta name="twitter:image" content={imageUrl} />

            {/* Optional: Add hashtags as keywords */}
            <meta name="keywords" content={art.hashtags.join(', ')} />
        </Helmet>
    );
};