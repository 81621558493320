import React, { useState } from 'react';
import GameWindowSize from '../../GameWindowSize';
import BackButton from './BackButton';
import ConversationCharacters from './ConversationCharacters';
import { ConversationBackground } from './ConversationBackground';
import { ChatCard } from './ChatCard';
import { ConversationCard } from './ConversationCard';
import { CHAT_RESPONSE } from '../../Queries/ChatResponse';
import { useMutation } from "@apollo/client";
import { ThinkingCard } from './ThinkingCard';

const ChatScreen = (props) => {
    const [isSummary, setIsSummary] = useState(true);
    const [dialogueIndex, setDialogueIndex] = useState(0);
    const [isPlayerTurn, setPlayerTurn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dialogue, setDialogue] = useState([]);

    const handleNext = (event) => {
        if (isSummary) {
            setIsSummary(false);
        } else {
            setDialogueIndex(dialogueIndex + 1);
        }

        setPlayerTurn(dialogueIndex >= dialogue.length - 1);
    };

    const [chatResponse] = useMutation(CHAT_RESPONSE);

    const handlePlayerText = async (event) => {
        setIsLoading(true);

        try {
            const playerQuestion = event.target[0].value;

            const { data } = await chatResponse({
                variables: {
                    conversationId: props.conversation._id,
                    playerQuestion: playerQuestion
                }
            });

            dialogue.push({ "agentName": "Player", "text": playerQuestion });

            data.chatResponse.dialogue.forEach((chat) => {
                dialogue.push({ "agentName": chat.agentName, "text": chat.text });
            });

            setDialogue(dialogue);
            setIsSummary(false);
            setPlayerTurn(false);
        } catch (error) {
            console.error('Error getting chat response:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const gameWindowSize = GameWindowSize({ width: 2048, height: 1024 });
    const currentY = gameWindowSize.height * 0.02;

    return (
        <div className="relative">
            <ConversationBackground
                conversation={props.conversation}
                width={gameWindowSize.width}
                height={gameWindowSize.height}
            />

            {/* Back Button positioned in top-left corner */}
            <div className="absolute top-4 left-4">
                <BackButton />
            </div>

            <ConversationCharacters
                isSummary={isSummary || isPlayerTurn}
                dialogueIndex={dialogueIndex}
                dialogue={dialogue}
                agents={props.conversation.agents}
                width={gameWindowSize.width}
                height={gameWindowSize.height}
                widthMultiplier={gameWindowSize.widthMultiplier}
                heightMultiplier={gameWindowSize.heightMultiplier}
                y={currentY}
            />

            {isLoading ? (
                <ThinkingCard
                    type="spinningBubbles"
                    color="#444"
                    width={gameWindowSize.width}
                    height={gameWindowSize.height}
                />
            ) : isPlayerTurn ? (
                <ChatCard
                    width={gameWindowSize.width}
                    height={gameWindowSize.height}
                    handlePlayerText={handlePlayerText}
                />
            ) : (
                <ConversationCard
                    isSummary={isSummary}
                    showNextButton={true}
                    dialogueIndex={dialogueIndex}
                    dialogue={dialogue}
                    summary={props.conversation.summary}
                    width={gameWindowSize.width}
                    height={gameWindowSize.height}
                    handleNext={handleNext}
                />
            )}
        </div>
    );
};

export default ChatScreen;