import { gql } from '@apollo/client'

export const GET_CHIRP = gql`
    query CHIRP($chirpId: ID!)
    {
        tweet(id: $chirpId)
        {
            success
            tweet
            {
                _id
                time
                content
                imageUrl
                author
                {
                    username
                    avatar
                }
                stats
                {
                    like_count
                    reply_count
                }
                replies
                {
                    _id
                    time
                    content
                    imageUrl
                    author
                    {
                        username
                        avatar
                    }
                    stats
                    {
                        like_count
                        reply_count
                    }
                }
                reply_to
                {
                    _id
                    author
                    {
                        avatar
                        username
                    }
                    content
                }
                agent
                {
                    _id
                    name
                    age
                    gender
                    status
                    emoji
                    description
                    agentDescription
                    {
                        eyes
                        hair
                        clothing
                        distinguishingFeatures
                        portraitFilename
                        iconFilename
                        resizedIconFilename
                        chibiFilename
                        resizedChibiFilename
                    }
                    twitterProfile
                    {
                        stats
                        {
                            tweet_count
                            followers_count
                            following_count
                        }
                    }
                    agentLocation
                    {
                        currentScenarioId
                        scenario
                        {
                            _id
                            name
                            imageFilename
                        }
                    }
                }
            }
        }
    }`