import React from 'react';

const Emoji = (props) => {

    return (
        <>
            {props.agent.emoji && (
                <p role="img"
                    style={{
                        position: 'absolute',
                        zIndex: 40,  // UI layer
                        paddingLeft: props.padding,
                        ...props.style
                    }}>
                    {props.agent.emoji}
                </p>
            )}
        </>
    );
}

export default Emoji