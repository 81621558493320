//import 'bootswatch/dist/slate/bootstrap.min.css';
import './App.css';
import './output.css'
import Overworld from './Queries/GetOverworld';
import Location from './Queries/GetLocation';
import Conversation from './Queries/GetConversation';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from './Profile/Auth0ProviderWithHistory';
import ApolloWrapper from './ApolloWrapper';
import { ToastProvider } from "./components/ui/toast"
import { Toaster } from "./components/ui/toaster"

import UserProfileLayout from './ProfilePage/UserProfileLayout';
import Newspaper from './Simulation/Newspaper/Newspaper';
import Edition from './Simulation/Newspaper/Edition';
import AgentChirpyScreen from './Chirpy/AgentChirpyScreen';
import AgentQuillScreen from './Quill/AgentQuillScreen';
import AgentChirpScreen from './Chirpy/AgentChirpScreen';
import AgentArtificeScreen from './Artifice/AgentArtificeScreen';
import AgentArtifactScreeen from './Artifice/AgentArtifactScreen';
import ImprovedLandingPage from './LandingPage/ImprovedLandingPage';
import MemoryFeed from './Stream/MemoryFeed';
import GetYapScreen from './Yap/GetYapScreen';
import FetchAgentPlans from './Plannerly/FetchAgentPlans';
import ScenarioChirpyScreen from './Chirpy/ScenarioChirpyScreen';
import ScenarioArtificeScreen from './Artifice/ScenarioArtificeScreen';

function App() {

    return (
        <BrowserRouter>
            <ToastProvider>
                <Auth0ProviderWithHistory>
                    <Routes>
                        <Route exact path="/" element={
                            <ApolloWrapper element={
                                <ImprovedLandingPage />
                            } />}
                        />
                        <Route path="/userprofile/" element={
                            <ApolloWrapper element={
                                <UserProfileLayout />
                            } />}
                        />
                        <Route exact path="/scenario/:id" element={
                            <ApolloWrapper element={
                                <Overworld />
                            } />}
                        />
                        <Route path="/location/:id" element={
                            <ApolloWrapper element={
                                <Location />
                            } />}
                        />
                        <Route path="/conversation/:id" element={
                            <ApolloWrapper element={
                                <Conversation isChat={false} />
                            } />}
                        />
                        <Route path="/chat/:id" element={
                            <ApolloWrapper element={
                                <Conversation isChat={true} />
                            } />}
                        />
                        <Route path="/newspaper/:id" element={
                            <ApolloWrapper element={
                                <Newspaper />
                            } />}
                        />
                        <Route path="/edition/:id" element={
                            <ApolloWrapper element={
                                <Edition />
                            } />}
                        />
                        <Route path="/chirpy/:id" element={
                            <ApolloWrapper element={
                                <AgentChirpyScreen />
                            } />}
                        />
                        <Route path="/scenariochirpy/:id" element={
                            <ApolloWrapper element={
                                <ScenarioChirpyScreen />
                            } />}
                        />
                        <Route path="/chirp/:id" element={
                            <ApolloWrapper element={
                                <AgentChirpScreen />
                            } />}
                        />
                        <Route path="/quill/:id" element={
                            <ApolloWrapper element={
                                <AgentQuillScreen />
                            } />}
                        />
                        <Route path="/artifice/:id" element={
                            <ApolloWrapper element={
                                <AgentArtificeScreen />
                            } />}
                        />
                        <Route path="/scenarioartifice/:id" element={
                            <ApolloWrapper element={
                                <ScenarioArtificeScreen />
                            } />}
                        />
                        <Route path="/artifact/:id" element={
                            <ApolloWrapper element={
                                <AgentArtifactScreeen />
                            } />}
                        />
                        <Route path="/stream/:id" element={
                            <ApolloWrapper element={
                                <MemoryFeed />
                            } />}
                        />
                        <Route path="/yap/:id" element={
                            <ApolloWrapper element={
                                <GetYapScreen />
                            } />}
                        />
                        <Route path="/plannerly/:id" element={
                            <ApolloWrapper element={
                                <FetchAgentPlans />
                            } />}
                        />
                    </Routes>
                </Auth0ProviderWithHistory>
                <Toaster />
            </ToastProvider>
        </BrowserRouter>
    );
}

//Elves 65eccd03635456e5173ad235
//NeoTokyo 65ff27fb6e43ac4559f147fc
//Pemberley 66004b13006cd24fff3ea55a
//Fishing Village 6603768518e6fc04d166200e
//Androids 660392f318e6fc04d16621ed
//Atlantis 66044d8e18e6fc04d16623c2
//Spooksville 662543e842a92a6aaba97c15
//Tortuga 662555b4a6e2b73baeb97e5e
//Swoleville 66256508a6e2b73baeb98052
//Athens 6625aacca6e2b73baeb98131

export default App;
