import React from 'react';
import { Card, CardContent } from '../components/ui/card';
import { Clock, Star } from 'lucide-react';

export const MemoryCard = ({ memory }) => {
    return (
        <Card className="mb-3">
            <CardContent className="pt-4">
                <p className="text-gray-800 mb-2">{memory.description}</p>
                <div className="flex items-center justify-between text-sm text-gray-500">
                    <div className="flex items-center">
                        <Clock className="w-4 h-4 mr-1" />
                        Time: {memory.time}
                    </div>
                    <div className="flex items-center">
                        <Star className="w-4 h-4 text-yellow-500 mr-1" />
                        {(memory.importance * 100).toFixed(0)}%
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};