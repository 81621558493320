import React from 'react';

export const Status = ({ agent }) => {
    return (
        <div
            className="absolute top-2 left-2 z-20 p-12 flex items-center justify-center"
            role="img"
        >
            <span className="text-2xl">{agent.emoji}</span>
        </div>
    );
};