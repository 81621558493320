import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const ArtifactHeader = ({ art }) => {
    const navigate = useNavigate();
    const background = art.scenario.imageFilename;

    return (
        <div>
            {/* Navigation Bar */}
            <div className="bg-gray-900">
                <div className="max-w-6xl mx-auto px-4 py-4">
                    <div className="flex items-center">
                        <button
                            onClick={() => navigate(-1)}
                            className="flex items-center text-white hover:text-gray-300 transition-colors"
                        >
                            <ArrowLeft className="h-6 w-6" />
                        </button>
                        <span className="ml-4 text-xl font-semibold text-white">Artifice</span>
                    </div>
                </div>
            </div>

            <div className="relative mb-6">
                {/* Banner Image */}
                <div className="h-48 bg-gradient-to-r from-blue-900 to-purple-900">
                    {background ? (
                        <img
                            src={`https://dyifmflum502e.cloudfront.net/${background}`}
                            alt="Profile background"
                            className="w-full h-full object-cover opacity-80"
                        />
                    ) : (
                        <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-purple-900" />
                    )}
                </div>

            </div>
        </div>
    );
};

export default ArtifactHeader;