import React from 'react';
import ArtifactHeader from './ArtifactHeader';
import Artifact from './Artifact';
import { ArtifactMeta } from './ArtifactMeta';

const ArtifactScreen = ({ art }) => {
    return (
        <div>
            <ArtifactMeta art={art} />
            <div className="w-full bg-gray-900">
                <div className="max-w-6xl mx-auto min-h-screen">
                    <ArtifactHeader art={art} />
                    <Artifact art={art} />
                </div>
            </div>
        </div>
    );
};

export default ArtifactScreen;