import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/tabs';
import { ScrollArea } from '../components/ui/scroll-area';
import { Badge } from '../components/ui/badge';
import { Clock, MapPin, Target, Calendar, ArrowRight } from 'lucide-react';

const AgentSchedule = ({ agent }) => {
    const [activeTab, setActiveTab] = useState('schedule');

    // Updated formatTime function to handle string format from API
    const formatTime = (dateTimeStr) => {
        // Check if dateTimeStr is already in a readable format (like from the API)
        if (typeof dateTimeStr === 'string' && dateTimeStr.includes('PM') || dateTimeStr.includes('AM')) {
            return dateTimeStr.split(' ')[0]; // Return just the time portion
        }

        // Otherwise, handle as Date object
        return new Date(dateTimeStr).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const getActivityStatus = (scenario, activity) => {
        // Parse the scenario's current date and time
        let now;
        if (typeof scenario.currentDateTime === 'string' &&
            (scenario.currentDateTime.includes('PM') || scenario.currentDateTime.includes('AM'))) {

            // The format is like "04:45 PM  Wednesday, January 15, 2200"
            const parts = scenario.currentDateTime.trim().split(/\s+/);
            const timeStr = parts[0]; // "04:45"
            const amPm = parts[1]; // "PM"
            const dateParts = parts.slice(3); // ["January", "15,", "2200"]

            // Construct a date string that JS can parse
            const dateStr = `${dateParts.join(' ')} ${timeStr} ${amPm}`;
            now = new Date(dateStr);
        } else {
            now = new Date(scenario.currentDateTime);
        }

        // Parse activity times
        let start, end;

        // Handle ISO format: "2200-01-15 09:00:00"
        if (typeof activity.startDateTime === 'string' && activity.startDateTime.includes('-')) {
            // Replace space with T for proper ISO format
            start = new Date(activity.startDateTime.replace(' ', 'T'));
            end = new Date(activity.endDateTime.replace(' ', 'T'));
        } else {
            start = new Date(activity.startDateTime);
            end = new Date(activity.endDateTime);
        }

        // Compare the times and return the status
        if (now >= start && now <= end) {
            return 'current';
        } else if (now < start) {
            return 'upcoming';
        } else {
            return 'completed';
        }
    };

    // Determine the background image to use
    const getBackgroundImage = () => {
        if (agent.agentLocation?.location?.resizedImageInteriorFilename) {
            return `https://dyifmflum502e.cloudfront.net/${agent.agentLocation.location.resizedImageInteriorFilename}`;
        } else if (agent.agentLocation?.scenario?.imageFilename) {
            return `https://dyifmflum502e.cloudfront.net/${agent.agentLocation.scenario.imageFilename}`;
        }
        return null;
    };

    const backgroundImage = getBackgroundImage();

    return (
        <div className="w-full max-w-4xl mx-auto space-y-4">
            {/* Combined Banner with profile pic, location and current activity */}
            <div
                className="w-full rounded-lg relative bg-cover bg-center shadow-lg overflow-hidden"
                style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none' }}
            >
                <div className="absolute inset-0 bg-black bg-opacity-60"></div>

                {/* Profile section */}
                <div className="relative z-10 p-4">
                    <div className="flex items-center space-x-4 mb-4">
                        {agent.agentDescription?.resizedIconFilename && (
                            <div className="w-16 h-16 rounded-full border-2 border-white overflow-hidden">
                                <img
                                    src={`https://dyifmflum502e.cloudfront.net/${agent.agentDescription.portraitFilename}`}
                                    alt={agent.name}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        )}
                        <div>
                            <h2 className="text-white font-bold text-xl">{agent.name}</h2>
                            <p className="text-white text-opacity-90 text-sm">{agent.status}</p>
                        </div>
                    </div>

                    {/* Location and Time section */}
                    <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center space-x-2">
                            <MapPin className="w-5 h-5 text-white" />
                            <span className="text-lg font-semibold text-white">{agent.agentLocation.location.name}</span>
                        </div>
                        <Badge variant="outline" className="text-white border-white">
                            <Clock className="w-4 h-4 mr-1" />
                            Current Time: {agent.agentLocation.scenario.currentDateTime}
                        </Badge>
                    </div>

                    {/* Current Activity */}
                    <div className="bg-white bg-opacity-90 rounded-lg p-4 mb-4">
                        <div className="text-sm text-violet-600 font-medium">Currently</div>
                        <div className="text-lg font-medium mt-1">{agent.currentActivity.description}</div>
                        {agent.currentActivity.startDateTime && agent.currentActivity.endDateTime && (
                            <div className="text-sm text-gray-500 mt-1">
                                {formatTime(agent.currentActivity.startDateTime)} - {formatTime(agent.currentActivity.endDateTime)}
                                {agent.currentActivity.timeFrame && ` (${agent.currentActivity.timeFrame})`}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Main Content Tabs */}
            <Tabs defaultValue="schedule" className="w-full">
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="schedule">
                        <Calendar className="w-4 h-4 mr-2" />
                        Schedule
                    </TabsTrigger>
                    <TabsTrigger value="goals">
                        <Target className="w-4 h-4 mr-2" />
                        Goals
                    </TabsTrigger>
                </TabsList>

                <TabsContent value="schedule" className="mt-4">
                    <Card>
                        <CardHeader>
                            <CardTitle>Today's Schedule</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <ScrollArea className="h-96 pr-4">
                                <div className="space-y-4">
                                    {agent.plannedActivities.map((activity, index) => {
                                        const status = getActivityStatus(agent.agentLocation.scenario, activity);
                                        return (
                                            <div
                                                key={index}
                                                className={`p-4 rounded-lg border transition-colors ${status === 'current' ? 'bg-violet-50 border-violet-200' :
                                                    status === 'completed' ? 'bg-gray-50 border-gray-200' :
                                                        'bg-white border-gray-200'
                                                    }`}
                                            >
                                                <div className="flex justify-between items-start">
                                                    <div>
                                                        <div className="font-medium">{activity.description}</div>
                                                        <div className="text-sm text-gray-500 mt-1">
                                                            {formatTime(activity.startDateTime)} - {formatTime(activity.endDateTime)}
                                                        </div>
                                                    </div>
                                                    <Badge
                                                        variant={status === 'current' ? 'default' : 'outline'}
                                                        className={status === 'current' ? 'bg-violet-500' : ''}
                                                    >
                                                        {status}
                                                    </Badge>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </ScrollArea>
                        </CardContent>
                    </Card>
                </TabsContent>

                <TabsContent value="goals" className="mt-4">
                    <Card>
                        <CardHeader>
                            <CardTitle>Agent Goals</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <ScrollArea className="h-96 pr-4">
                                <div className="space-y-4">
                                    {agent.goals.map((goal, index) => (
                                        <div key={index} className="p-4 rounded-lg border bg-white">
                                            <div className="flex items-start space-x-3">
                                                <div className="flex-grow">
                                                    <div className="font-medium">{goal.title}</div>
                                                    <div className="text-sm text-gray-500 mt-1">{goal.description}</div>
                                                    <Badge variant="outline" className="mt-2">
                                                        {goal.timeFrame}
                                                    </Badge>
                                                </div>
                                                <ArrowRight className="w-5 h-5 text-gray-400" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </ScrollArea>
                        </CardContent>
                    </Card>
                </TabsContent>
            </Tabs>
        </div>
    );
};

export default AgentSchedule;