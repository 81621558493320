import React from 'react';
import Emoji from './Emoji';

const OverworldChibi = (props) => {

    // Safely access the filename with optional chaining
    const iconUrl = props.agent.agentDescription?.resizedChibiFilename
        ? `https://dyifmflum502e.cloudfront.net/${props.agent.agentDescription.resizedChibiFilename}`
        : '/api/placeholder/128/224'; // Fallback placeholder image

    return (
        <div style={{
            position: 'absolute',
            left: props.x,
            top: props.y,
            width: 96 * props.widthMultiplier,
            height: 96 * props.heightMultiplier,
            zIndex: 30  // Character layer
        }}>
            <img
                src={iconUrl}
                width={96 * props.widthMultiplier}
                height={96 * props.heightMultiplier}
                style={{ position: 'absolute' }} />
            <Emoji
                agent={props.agent}
                style={{ position: 'absolute', zIndex: 40 }} />
        </div>
    );
}

export default OverworldChibi