import { useQuery } from '@apollo/client'
import React from 'react';
import ReactLoading from 'react-loading';
import { GET_AGENT_JOURNAL } from '../Queries/GetJournal';
import { useParams } from 'react-router-dom';
import QuillScreen from './QuillScreen';

const AgentQuillScreen = (props) => {

    const { id } = useParams();

    const { loading, error, data } = useQuery(GET_AGENT_JOURNAL, {
        variables: { agentId: props.agentId != null ? props.agentId : id }
    });

    if (loading) {
        return (
            <ReactLoading type="spinningBubbles" color="#444" />
        );
    }
    else if (error) {
        return (
            <p>Something went wrong...</p>
        );
    }
    else {
        return (
            <div>
                <QuillScreen agent={data.agent.agent} />
            </div>
        )
    }
}

export default AgentQuillScreen;