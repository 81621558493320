import { gql } from '@apollo/client'

export const GET_AGENT_ARTIFICE = gql`
    query AGENT($agentId: ID!)
    {
        agent(id: $agentId)
        {
            success
            agent 
            {
                _id
                name
                age
                gender
                status
                emoji
                description
                agentDescription
                {
                    eyes
                    hair
                    clothing
                    distinguishingFeatures
                    portraitFilename
                    iconFilename
                    resizedIconFilename
                    chibiFilename
                    resizedChibiFilename
                }
                agentLocation
                {
                    currentScenarioId
                    scenario
                    {
                        name
                        imageFilename
                    }
                }
                artifice
                {
                    _id
                    date_created
                    image_url
                    prompt
                    negative_prompt
                    hashtags
                    caption
                    scenario
                    {
                        name
                        imageFilename
                    }
                    artists
                    {
                        _id
                        name
                        agentDescription
                        {
                            eyes
                            hair
                            clothing
                            distinguishingFeatures
                            portraitFilename
                            iconFilename
                            resizedIconFilename
                            chibiFilename
                            resizedChibiFilename
                        }
                    }
                    caption
                }
            }
        }
    }`