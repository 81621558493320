import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';

const LocationLabel = (props) => {
    const navigate = useNavigate();

    const x = props.x + (props.width * 0.5);
    const y = props.y + (props.height * 0.8);

    return (
        <Button
            variant="secondary"
            className="absolute bg-primary hover:bg-primary/90 text-primary-foreground shadow-lg"
            style={{
                position: 'absolute',
                zIndex: 40,  // UI layer - same as other interactive elements
                marginTop: 16,
                left: x,
                top: y,
                transform: 'translate(-50%)'
            }}
            onClick={() => navigate(`/location/${props.location._id}`)}>
            {props.location.name}
        </Button>
    );
}

export default LocationLabel;